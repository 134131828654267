import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "../ui/card";
import {Badge} from "../ui/Badge";
import {Button} from "../ui/Button";
import SupabaseTable from "../../models/supabase_table";

function CourseCard(item: SupabaseTable, index: number) {
    const buttonText = item.courses.length > 0 ? 'Learn More' : 'Coming Soon';
    return (<Card key={index}
                  className={`group w-full sm:w-[325px] sm:min-h-[400px] bg-white p-6 flex flex-col hover:outline hover:outline-[#F4AF1B] hover:outline-4 mb-4 sm:mb-0`}>
        <CardHeader>
            <CardTitle className="text-3xl font-bold group-hover:text-[#F4AF1B]">{item.name}</CardTitle>
            <div className="text-sm text-muted-foreground">
                <Badge className={`text-2xl font-kFNaskhBold px-3 group-hover:bg-[#F4AF1B]`}>
                    {item.arabicName}
                </Badge>
            </div>
        </CardHeader>
        <CardContent className="flex-grow">
            <ul className="list-disc pl-5 space-y-1">
                {item.courses.map((course, index) => (
                    <li key={index} className="text-xl text-gray-600">{course.title}</li>))}
            </ul>
        </CardContent>
        <CardFooter>
            <Button
                className={`group-hover:bg-[#F4AF1B] group-hover:text-white`}
                variant="outline"
            >
                {buttonText}
            </Button>
        </CardFooter>
    </Card>);
}

export default function Courses(props: any) {
    const tables: SupabaseTable[] = props.tables;
    return (<div id="courses" className="bg-[#0f172a] p-10 text-start">
        <div className="md:flex flex-wrap justify-evenly gap-4 mt-4">
            {tables.map((item, index) => {
                const courseCard = CourseCard(item, index);
                if (item.courses.length > 0) {
                    return (<a href={`/${item.name.toLowerCase()}`}>
                        {courseCard}
                    </a>)
                }

                return courseCard;
            })}
        </div>
    </div>)
}

