import {HoverCard, HoverCardContent, HoverCardTrigger} from "../ui/hover_card";
import Constants from "../../utils/constants";
import ustadhUmair from "./ustadh_umair.svg";
import ustadhUzair from "./ustadh_uzair.svg";

export default function Instructors(props:any) {
    const id = props.id ?? "instructors";
    return (<section id={id} className="w-full py-12 md:py-24 lg:py-32">
        <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6 lg:gap-10">
            <div className="space-y-3">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Meet our
                    Instructors</h2>
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                    We are committed to providing students with a supportive and engaging learning experience and
                    are dedicated
                    to helping each student reach their full potential.
                </p>
            </div>
            <div className="grid w-full grid-cols-1 md:grid-cols-2 items-stretch justify-center">
                <HoverCard>
                    <HoverCardTrigger asChild>
                        <div className="mx-auto flex w-full items-center justify-center p-4 sm:p-8 text-start">
                            <img
                                alt="Ustadh Umair"
                                className="aspect-[1/1] overflow-hidden rounded-full object-contain object-center border-2 border-black"
                                height="140"
                                src={ustadhUmair}
                                width="140"
                            />
                            <div className="space-y-2 ml-4">
                                <h4 className="text-2xl font-bold">{Constants.ustadhUmair}</h4>
                                <h4 className="text-sm font-semibold">Co-Founder of Markaz Umaza</h4>
                                <p className="text-gray-500">{Constants.qualificationsUmair}</p>
                            </div>
                        </div>
                    </HoverCardTrigger>
                    <HoverCardContent className="w-80">
                        <div className="flex justify-between space-x-4">
                            <div className="space-y-1 text-left">
                                <h4 className="text-sm font-bold">{Constants.ustadhUmair}</h4>
                                <h4 className="text-sm font-semibold">Co-Founder of Markaz Umaza</h4>
                                <p className="text-sm">
                                    {Constants.aboutUmair}
                                </p>
                            </div>
                        </div>
                    </HoverCardContent>
                </HoverCard>
                <HoverCard>
                    <HoverCardTrigger asChild>
                        <div className="mx-auto flex w-full items-center justify-center p-4 sm:p-8 text-start">
                            <img
                                alt="Ustadh Uzair"
                                className="aspect-[1/1] overflow-hidden rounded-full object-contain object-center border-2 border-black"
                                height="140"
                                src={ustadhUzair}
                                width="140"
                            />
                            <div className="space-y-2 ml-4">
                                <h4 className="text-2xl font-bold">{Constants.ustadhUzair}</h4>
                                <h4 className="text-sm font-semibold">Co-Founder of Markaz Umaza</h4>
                                <p className="text-gray-500">{Constants.qualificationsUzair}</p>
                            </div>
                        </div>
                    </HoverCardTrigger>
                    <HoverCardContent className="w-80">
                        <div className="flex justify-between space-x-4">
                            <div className="space-y-1 text-left">
                                <h4 className="text-sm font-bold">{Constants.ustadhUzair}</h4>
                                <h4 className="text-sm font-semibold">Co-Founder of Markaz Umaza</h4>
                                <p className="text-sm">
                                    {Constants.aboutUzair}
                                </p>
                            </div>
                        </div>
                    </HoverCardContent>
                </HoverCard>
            </div>
        </div>
    </section>)
}

