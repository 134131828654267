import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "../ui/card";
import {Badge} from "../ui/Badge";
import {Button} from "../ui/Button";
import {Dialog, DialogContent, DialogTrigger} from "../ui/Dialog";

function SignUpForm(props: any) {
    const title = props.title
    const url = props.url;

    if (!url) {
        return null;
    }

    return <DialogContent className="w-11/12 md:min-w-[550px] min-h-[450px] md:min-h-[550px] max-w-[550px] max-h-[850px] md:max-w-md">
        <div >
            <iframe title={title} src={url} width="100%" height="100%"/>
            <script src="https://www.cognitoforms.com/f/iframe.js"/>
        </div>
    </DialogContent>
}

export default function SubCourses(props: any) {
    const courses: any[] = props.courses;
    return (<div id="subCourses" key="1" className="bg-[#0f172a] p-6 md:p10 text-start">
        <div className="flex flex-wrap justify-evenly gap-4 mt-4">
            {courses.map((item: any, index) => (<Card
                className={`group w-full sm:w-[475px] sm:min-h-[600px] bg-white p4 md:p-6 flex flex-col hover:outline hover:outline-[#F4AF1B] hover:outline-4 mb-4 sm:mb-0`}>
                <CardHeader>
                    <CardTitle className="text-3xl font-bold group-hover:text-[#F4AF1B]">{item['title']}</CardTitle>
                    <CardDescription>
                        <Badge className={`text-2xl font-bold px-3 group-hover:bg-[#F4AF1B]`}>
                            {item['instructor']}
                        </Badge>
                    </CardDescription>
                </CardHeader>
                <CardContent className="flex-grow">
                    <div className="text-xl text-gray-600 line-clamp-[10] md:line-clamp-none">{item['brief']}</div>
                </CardContent>
                <CardFooter>
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button
                                className={`group-hover:bg-[#F4AF1B] group-hover:text-white`}
                                variant="outline"
                            > Join Now
                            </Button>
                        </DialogTrigger>
                        <SignUpForm url={item['signup_url']} title={item['title']}/>
                    </Dialog>
                </CardFooter>
            </Card>))}
        </div>
    </div>)
}

