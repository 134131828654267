import {Carousel, CarouselContent, CarouselItem} from "../ui/Carousel";
import Autoplay from "embla-carousel-autoplay"

export default function CourseHeader(props: any) {
    const title: string = props.title;
    const description: string = props.description;
    const images: string[] = props.images;

    return (<div id="course-home"
                 className="w-full max-w bg-[#435673] md:flex justify-evenly text-center md:text-left text-white p-8">

        <div className="max-w-3xl mt-auto mb-auto ml-8 mr-8 text-center md:text-left">
            <h2 className="text-5xl font-bold text-center md:text-left ">{title}</h2>
            <p className="mt-4 text-xl text-gray-300 text-center md:text-left">
                {description}
            </p>
        </div>
        <Carousel className="md:w-4/6" opts={{
            align: "start", loop: true,
        }} plugins={[Autoplay({delay: 5000})]}>
            <CarouselContent>
                {images.map((item, index) => (<CarouselItem className="md:mx-auto mt-12 mb-0 md:mb-12" key={index}>
                    <div className="md:w-4/6 mt-16 md:m-auto">
                        <img
                            src={item} alt="poster"/>
                    </div>
                </CarouselItem>))}
            </CarouselContent>
        </Carousel>

    </div>)

};
