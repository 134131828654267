import React, {useEffect, useState} from 'react';
import './App.css';
import NavBar from "./components/NavBar";
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import Header from "./components/header/Header";
import Courses from "./components/courses/Courses";
import {createClient} from "@supabase/supabase-js";
import SupabaseTable from "./models/supabase_table";
import Constants from "./utils/constants";
import Poster from "./models/poster";
import Footer from "./components/Footer";
import Instructors from "./components/instuctors/Instructors";
import CoursesInfo from './components/courses/CoursesInfo';
import Meeting from './components/Meeting';
import './fonts/kf_naskh.ttf';
import './fonts/kf_naskh_b.ttf';

const supabase = createClient(Constants.supabaseURL, Constants.supabaseAnonKey);
let fetchedTables = false;

function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
}

function Home(props: any) {
    const tables: SupabaseTable[] | null = props.tables;
    const posters: Poster[] | null = props.posters;
    const location = useLocation();

    useEffect(() => {
        document.title = "Markaz Umaza | Home";

        timeout(500).then(_ => {
            if (location.hash !== '') {
                const section = document.querySelector(location.hash);
                section?.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        })

    }, [location]);


    return (<div>
        <Header posters={posters ?? []}/>
        <Courses tables={tables ?? []}/>
        <Instructors/>
    </div>);
}

function App() {
    const [tables, setTables] = useState<SupabaseTable[] | null>([]);
    const [posters, setPosters] = useState<Poster[] | null>([]);

    useEffect(() => {
        getTables().then();
    },);

    async function getTables() {
        if (fetchedTables) {
            return;
        }

        const posts: Poster[] = [];
        for (const table of Constants.tables) {
            const courses = (await supabase.from(table.name).select()).data ?? [];
            table.courses = courses.sort((a, b) => {
                const aOrder = a['order'];
                const bOrder = b['order'];

                if (aOrder < bOrder) {
                    return -1;
                } else if (aOrder > bOrder) {
                    return 1;
                }

                return 0;
            })
            courses.forEach(course => {
                if (course['image_url'] != null) {
                    posts.push(new Poster(course['image_url'], course.title, course.brief, table.name));
                }
            });

        }
        setTables(Constants.tables);
        setPosters(posts);
        fetchedTables = true;
    }

    if (tables == null || tables.length === 0) {
        return (<div className="w-screen h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
            <div className="space-y-4">
                <div className="w-12 h-12 text-blue-500"/>
                <p className="text-lg font-medium text-gray-700 dark:text-gray-300">Loading...</p>
            </div>
        </div>);
    }

    return (<BrowserRouter>
        <div className="App">
            <NavBar/>
            <Routes>
                <Route path="/" element={<Home tables={tables} posters={posters}/>}/>
                {Constants.tables.map((table, index) => (
                    <Route key={index} path={`/${table.name.toLowerCase()}`} element={<CoursesInfo table={table}/>}/>))}
                <Route path={`/class`} element={<Meeting/>}/>))
            </Routes>
            <Footer/>
        </div>
    </BrowserRouter>);
}

export default App;
