import Staff from "../models/staff";
import SupabaseTable from "../models/supabase_table";
import Poster from "../models/poster";

export default class Constants {
    static supabaseURL = 'https://aovmdoyphcjkbgtfqcvq.supabase.co';
    static supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFvdm1kb3lwaGNqa2JndGZxY3ZxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDE2MDA5NjMsImV4cCI6MjAxNzE3Njk2M30.r2loluPUA7kBawu_TupdO4ohb9dnhnSHhK7Ej2U_yiA';
    static instagramLink = 'https://www.instagram.com/markazumaza/';
    static youtubeLink = 'https://www.youtube.com/channel/UCskTDnKVKDA2oTGi6xBAqDw';
    static telegramLink = 'https://t.me/markazumaza';
    static email = 'm.saleem@markazumaza.com';
    static phoneNum = '+1 (289) 456-9089';

    static meetInstructors = 'Meet Our Instructors';
    static meetInstructorsSubtitle = 'We are committed to providing students with a supportive and engaging learning experience and are dedicated to helping each student reach their full potential.';
    static ustadhUzair = 'Ustadh Uzair';
    static qualificationsUzair = 'Bachelors of Education in Quran Sciences from King Saud university, Riyadh';
    static aboutUzair = 'Born in Pakistan and raised in Canada, Ustadh Mohammad Uzair completed his secondary schooling in Canada. In 2013, he accomplished the memorization of the Quran and holds an Ijazah in the Quran (in Hafs An Asim Min Tariq Shatabiyyah). To further his knowledge, he pursued undergraduate studies in Saudi Arabia, graduating from King Saud University in Riyadh with a bachelor’s degree in education (B.Ed.), with a major in Quranic Studies. He has been actively involved in his local community, teaching Quran and other Islamic subjects, and occasionally delivering lectures and khutbahs. He is passionate about teaching and loves a student-centric approach. ';
    static ustadhUmair = 'Ustadh Umair';
    static qualificationsUmair = 'Bachelors of Art in Arabic Linguistics from the Islamic University of Madina';
    static aboutUmair = 'Raised in Canada, Ustadh Mohammad Umair completed his memorization of the Quran in 2013. Motivated by a thirst for knowledge, he embarked on a journey to the esteemed city of the Prophet (PBUH) and ultimately earned a Bachelor\'s degree in Arabic Linguistics (BA) from the Islamic University in Medina, Saudi Arabia. His dedication to continuous learning extends beyond Arabic, as he has also acquired expertise in English phonetics, enabling him to draw insightful comparisons between the two languages. As an enthusiastic and compassionate instructor, he prioritizes the individual needs of each student and tailors his curriculum to match the level of each student.';

    static teachers: Staff[] = [new Staff('Ustadh Mohammad Umair Saleem', 'Co-Founder of Markaz Umaza',

        "Raised in Canada, Ustadh Mohammad Umair completed his memorization of the Quran in 2013. Motivated by a thirst for knowledge, he embarked on a journey to the esteemed city of the Prophet (PBUH) and ultimately earned a Bachelor's degree in Arabic Linguistics (BA) from the Islamic University in Medina, Saudi Arabia. His dedication to continuous learning extends beyond Arabic, as he has also acquired expertise in English phonetics, enabling him to draw insightful comparisons between the two languages. As an enthusiastic and compassionate instructor, he prioritizes the individual needs of each student and tailors his curriculum to match the level of each student.",), new Staff('Ustadh Mohammad Uzair Saleem', 'Co-Founder of Markaz Umaza',

        "Born in Pakistan and raised in Canada, Ustadh Mohammad Uzair completed his secondary schooling in Canada. In 2013, he accomplished the memorization of the Quran and holds an Ijazah in the Quran (in Hafs An Asim Min Tariq Shatabiyyah). To further his knowledge, he pursued undergraduate studies in Saudi Arabia, graduating from King Saud University in Riyadh with a bachelor’s degree in education (B.Ed.), with a major in Quranic Studies. He has been actively involved in his local community, teaching Quran and other Islamic subjects, and occasionally delivering lectures and khutbahs. He is passionate about teaching and loves a student-centric approach.",),];

    static tables: SupabaseTable[] = [
        new SupabaseTable('Arabic', 'العربية', 'Immerse yourself in the rich tapestry of the Arabic language through our comprehensive Arabic course. From foundational grammar and vocabulary to advanced reading and writing skills, this course offers a holistic approach to mastering Arabic. Whether you\'re interested in understanding the Quran, engaging with Arabic literature, or communicating effectively, this path is your gateway to fluency.'),
        new SupabaseTable('Tajwid', 'التجويد', 'Embark on a journey of intellectual exploration with the Tafsir course. Delve deep into the meanings and interpretations of the Quranic verses, guided by scholars who will unveil the historical context, linguistic nuances, and profound insights within the text. This course will empower you to gain a deeper understanding of the Quran\'s message and its relevance to contemporary life.'),
        new SupabaseTable('Hifz', 'الحفظ', 'Immerse yourself in the spiritual journey of memorizing the Quran through the Hifz course. Delve into the profound verses of the Quran, guided by experienced instructors who will provide personalized guidance, helping you develop a deep connection with the sacred text and nurturing your devotion.'),
        new SupabaseTable('Tafsir', 'التفسير', 'Delve into the depths of understanding the Quran through our Tafsir courses. Uncover the layers of meaning, historical context, and wisdom behind each verse, as our expert scholars lead you through profound insights. Enrich your connection with the Quranic teachings by exploring its interpretations with us.'),
        new SupabaseTable('General', 'عام', ''),
    ];

    static posters: Poster[] = [];
}