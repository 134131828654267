import React from "react";
import {useLocation} from "react-router-dom";

import "@whereby.com/browser-sdk/embed";

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Meeting() {
    let query = useQuery();

    return (<div className="bg-[#435673]">
        <whereby-embed background='off' room={`https://markazumaza.whereby.com/${query.get('code')}`}
                       style={{"height": "52rem"}}/>
    </div>)

}