import Constants from "../utils/constants";

export default function Footer() {
    return (<div id="contact" className="bg-gray-800 text-white py-6">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="flex items-center space-x-4">
                    <div>
                        <h2 className="text-lg font-bold">Markaz Umaza</h2>
                        <p className="text-sm">© 2023 Markaz Umaza</p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-between items-start text-start">
                    <div className="space-y-1">
                        <p className="text-sm">Phone: {Constants.phoneNum}</p>
                        <p className="text-sm">Telegram: {Constants.phoneNum}</p>
                        <p className="text-sm">Email: {Constants.email}</p>
                    </div>
                    <div className="flex items-center space-x-4 mt-4 sm:mt-0">
                        <a className="text-gray-300 hover:text-white" href={Constants.instagramLink} target="_blank"
                           rel="noopener noreferrer">
                            <InstagramIcon className="h-6 w-6"/>
                        </a>
                        <a className="text-gray-300 hover:text-white" href={Constants.youtubeLink} target="_blank"
                           rel="noopener noreferrer">
                            <YoutubeIcon className="h-6 w-6"/>
                        </a>
                        <a className="text-gray-300 hover:text-white" href={Constants.telegramLink} target="_blank"
                           rel="noopener noreferrer">
                            <TelegramIcon className="h-6 w-6"/>
                        </a>
                    </div>
                </div>
            </div>
    </div>)
}

function InstagramIcon(props: any) {
    return (<svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"/>
            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/>
            <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"/>
        </svg>)
}


function YoutubeIcon(props: any) {
    return (<svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                d="M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17"/>
            <path d="m10 15 5-3-5-3z"/>
        </svg>)
}

function TelegramIcon(props: any) {
    return (<svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path xmlns="http://www.w3.org/2000/svg"
              d="m29.919 6.163-4.225 19.925c-.319 1.406-1.15 1.756-2.331 1.094l-6.438-4.744-3.106 2.988c-.344.344-.631.631-1.294.631l.463-6.556 11.931-10.781c.519-.462-.113-.719-.806-.256l-14.75 9.288-6.35-1.988c-1.381-.431-1.406-1.381.288-2.044l24.837-9.569c1.15-.431 2.156.256 1.781 2.013z"/>
    </svg>)
}