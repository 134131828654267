import {Label} from "../ui/Label"
import {Input} from "../ui/Input"
import {Button} from "../ui/Button"
import {DialogClose, DialogContent} from "../ui/Dialog"
import {Link} from "react-router-dom"
import {useState} from "react";

export default function JoinClass() {
    const [code, setCode] = useState<string>('');

    return (<DialogContent className="max-w-[350px] md:max-w-md">
        <div className="p-6 m-auto">
            <h1 className="text-3xl font-semibold text-center text-gray-700 dark:text-white">Join Class</h1>
            <p className="mt-4 text-center text-gray-500 dark:text-gray-400">
                Please enter the room code to join the class.
            </p>
            <form className="flex flex-col mt-8 space-y-4">
                <div className="flex flex-col space-y-1">
                    <Label className="text-sm font-medium text-gray-700 dark:text-gray-200" htmlFor="room-code">
                        Room Code
                    </Label>
                    <Input
                        className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-500"
                        id="room-code"
                        placeholder="Enter room code"
                        required
                        type="text"
                        onChange={value => setCode(value.target.value)}
                    />
                </div>
                <DialogClose asChild>
                    <Button asChild
                            className="w-full px-4 py-2 font-medium tracking-wide text-white transition-colors duration-200 transform bg-[#0f172a] rounded-md hover:bg-[#000] focus:outline-none focus:bg-[#0f172a]-500"
                            type="submit"
                    >
                        <Link to={`/class?code=${code}`}>Join Class</Link>
                    </Button>
                </DialogClose>
            </form>
        </div>
    </DialogContent>)
}

