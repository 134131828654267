export default class Poster {
    constructor(image: string, title: string, description: string, table: string) {
        this.image = image;
        this.title = title;
        this.description = description;
        this.table = table;
    }

    image: string;
    title: string;
    description: string;
    table: string;
}