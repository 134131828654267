import {Badge} from "../ui/Badge"
import Poster from "../../models/poster";
import {Carousel, CarouselContent, CarouselItem} from "../ui/Carousel";
import Autoplay from "embla-carousel-autoplay"

export default function Header(props: any) {
    const posters: Poster[] = props.posters;

    return (<div id="home" className="bg-[#435673] text-white">
        <Carousel className="w-full max-w" opts={{
            align: "start", loop: true,
        }} plugins={[Autoplay({delay: 5000}),]}>
            <CarouselContent>
                <CarouselItem key={0}>
                    <div className="md:flex justify-between md:px-4 md:py-2 text-left">
                        <div className="hidden md:block my-auto p-8 text-left max-w-3xl w-4/6">
                            <h2 className="text-5xl font-bold text-left">Welcome to Markaz Umaza</h2>
                            <p className="mt-4 text-xl text-gray-300 text-left">
                                We teach traditional Islamic Knowledge with a classical understanding while utilizing
                                modern techniques to enhance the learning experience.
                            </p>
                        </div>
                        <div className="md:w-2/6 md:m-auto">
                            <img
                                src="https://aovmdoyphcjkbgtfqcvq.supabase.co/storage/v1/object/public/Posters/main_poster.png" alt="main poster" className="md:p-4"/>
                        </div>
                    </div>
                </CarouselItem>
                {posters.map((item, index) => (<CarouselItem key={index + 1}>
                    <div className="md:flex justify-between md:px-4 md:py-2 text-left">
                        <div className="hidden md:block my-auto p-8 text-left max-w-3xl w-4/6">
                            <h2 className="text-5xl font-bold text-left">{item.title}</h2>
                            <Badge
                                className="mt-3 md:mt-4 bg-black text-white text-xl md:text-2xl text-center md:text-left">
                                {item.table}
                            </Badge>
                            <p className="mt-4 text-xl text-gray-300 text-left">
                                {item.description}
                            </p>
                        </div>
                        <div className="md:w-2/6 md:m-auto">
                            <a href={`/${item.table.toLowerCase()}`}>
                                <img
                                    src={item.image} alt="poster" className="md:p-4"/>
                            </a>
                        </div>
                    </div>
                </CarouselItem>))}
            </CarouselContent>
        </Carousel>
    </div>)

};
