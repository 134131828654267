import './Navbar.css'
import * as React from 'react';
import {HashLink} from 'react-router-hash-link';
import {Dialog, DialogTrigger} from './ui/Dialog';
import {Button} from './ui/Button';
import JoinClass from "./class/join_class";
import {Sheet, SheetClose, SheetContent, SheetTrigger} from "./ui/Sheet";

const navItems = ['Home', 'Courses', "Instructors", 'Contact', 'Join Class'];

export default function NavBar() {
    return (<header className={`top-0 w-full bg-white dark:bg-gray-800 shadow-md`}>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">Markaz Umaza</h1>
                    </div>
                </div>
                <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                        {navItems.map((item, index) => {
                            if (index === navItems.length - 1) {
                                return <Dialog key={index}>
                                    <DialogTrigger asChild>
                                        <Button
                                            className={`hover:bg-gray-700 bg-transparent text-gray-800 dark:text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}>
                                            {item}
                                        </Button>
                                    </DialogTrigger>
                                    <JoinClass/>
                                </Dialog>
                            }
                            return (<HashLink smooth
                                              className={`hover:bg-gray-700 text-gray-800 dark:text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}
                                              to={`/#${item.toLowerCase()}`} key={index}
                            >
                                {item}
                            </HashLink>)
                        })}
                    </div>
                </div>
                <div className="md:hidden">
                    <Sheet>
                        <SheetTrigger asChild>
                            <Button size="icon" variant="outline">
                                <MenuIcon className="h-6 w-6"/>
                                <span className="sr-only">Toggle navigation menu</span>
                            </Button>
                        </SheetTrigger>
                        <SheetContent side="right">
                            <div className="grid gap-2 py-6">
                                {navItems.map((item, index) => {
                                    if (index === navItems.length - 1) {
                                        return <Dialog key={index}>
                                            <DialogTrigger asChild>
                                                <Button
                                                    className={`bg-transparent block p-0 rounded-md text-lg font-semibold text-[#0f172a] hover:text-[#0f172a] hover:bg-transparent items-start text-start`}>
                                                    {item}
                                                </Button>
                                            </DialogTrigger>
                                            <JoinClass/>
                                        </Dialog>
                                    }

                                    return <SheetClose asChild key={index}>
                                        <HashLink
                                            className="flex w-full items-center py-2 text-lg font-semibold text-[#0f172a]"
                                            to={`/#${item.toLowerCase()}`}>
                                            {item}
                                        </HashLink>
                                    </SheetClose>
                                })}
                            </div>
                        </SheetContent>
                    </Sheet>
                </div>
            </div>
        </div>

    </header>);
}

function MenuIcon(props: any) {
    return (<svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <line x1="4" x2="20" y1="12" y2="12"/>
        <line x1="4" x2="20" y1="6" y2="6"/>
        <line x1="4" x2="20" y1="18" y2="18"/>
    </svg>)
}