export default class SupabaseTable {
    name: string;
    arabicName: string;
    courses: any[];
    description: string;

    constructor(name: string, arabicName: string, description: string, courses: any[] = []) {
        this.name = name;
        this.arabicName = arabicName;
        this.courses = courses;
        this.description = description;
    }
}