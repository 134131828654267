import SupabaseTable from "../../models/supabase_table";
import React, {useEffect} from "react";
import Instructors from "../instuctors/Instructors";
import CourseHeader from "./CourseHeader";
import SubCourses from "./SubCourses";

export default function Courses(props: any) {
    const table: SupabaseTable = props.table;
    const posters = table.courses.filter(item => item['image_url'] != null).map(item => item['image_url']);

    useEffect(() => {
        document.title = `Markaz Umaza | ${table.name}`;
    }, [table.name]);

    return (<div className="course-info">
        <CourseHeader title={table.name} description={table.description}
                      images={posters}/>
        <SubCourses courses={table.courses}/>
        <Instructors id="courseInstructors"/>

    </div>)
}

