export default class Staff {
    constructor(name: string, role: string, description: string) {
        this.name = name;
        this.role = role;
        this.description = description;
    }

    name: string;
    role: string;
    description: string;
}